import React from 'react'
import PropTypes from 'prop-types'
import ShapeHeading from '../../svg/shape-header.svg'
import ShapeBanner from '../../svg/shape-banner.svg'

const Shape = ({ heading }) =>
  heading ? (
    <ShapeHeading className="absolute w-full h-3 md:inset-x-0 bottom-0 min-w-screen-xs md:min-w-screen-lg z-10" />
  ) : (
    <ShapeBanner
      className="absolute inset-0 w-full min-w-screen-lg -ml-16 md:ml-0 z-10"
      style={{ height: `calc(100% + 1px)` }}
    />
  )

export default Shape

Shape.propTypes = {
  heading: PropTypes.bool,
}
